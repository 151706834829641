import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { CleaningDetailes, Footer, Navbar, Top } from "views";
import { AboutLandings, Contact } from "views/Sections";
import { AlternativeNavigation, SEO } from "components";

import { findNodeBy } from "utils/breakDownAllNodes";
import "utils/fixFontAwesome";

import "../../style/main.scss";
import VideoLanding from "content/assets/videos/cleaning-720p.webm";
import VideoThumb from "content/assets/videos/cleaning-720p-thumbnail-0001.webp";
import InspectionDetails from "views/Sections/InspectionDetails";

export const query = graphql`
  query cleaningQuery($langKey: String!) {
    site {
      siteMetadata {
        keywords
        description
      }
    }
    allMarkdownRemark(filter: { fields: { langKey: { eq: $langKey } } }) {
      nodes {
        i18n: frontmatter {
          ...NavbarMarkdownRemarkFragment
          ...LandingsNavigationMarkdownRemarkFragment
          ...TopMarkdownRemarkFragment
          ...CleaningMarkdownRemarkFragment
          ...AboutMarkdownRemarkFragment
          ...ContactMarkdownRemarkFragment
          ...CallbackMarkdownRemarkFragment
          ...SendmailMarkdownRemarkFragment
          ...FooterMarkdownRemarkFragment
          ...ContactUsMarkdownRemarkFragment
        }
        fields {
          fileName
          directoryName
        }
      }
    }
  }
`;

const Cleaning = ({ data, pageContext }) => {
  const { langKey } = pageContext;
  const {
    site: {
      siteMetadata: { keywords, description },
    },
    allMarkdownRemark: { nodes },
  } = data;

  const topNode = findNodeBy(nodes, /cleaningtop\./i);
  const navBarNode = findNodeBy(nodes, /navbar\./i);
  const aboutNode = findNodeBy(nodes, /about\./i);
  const contactNode = findNodeBy(nodes, /contact\./i);
  const cleaningNode = findNodeBy(nodes, /cleaningmain\./i);
  const inspectionNode = findNodeBy(nodes, /inspection\./i, /pages/i);
  const footerNode = findNodeBy(nodes, /footer\./i);
  const sitemapNode = findNodeBy(nodes, /sitemap\./i);
  const callbackNode = findNodeBy(nodes, /callback\./i);
  const contactUsNode = findNodeBy(nodes, /contactus\./i);

  const seo = { lang: pageContext.langKey, keywords, description };

  return (
    <div>
      <SEO title={topNode.i18n.header} {...seo} />
      <Navbar frontmatter={navBarNode.i18n} langKey={pageContext.langKey}>
        <AlternativeNavigation context={pageContext} frontmatter={sitemapNode.i18n} />
      </Navbar>
      <Top frontmatter={topNode.i18n} poster={VideoThumb} video={VideoLanding} />

      <CleaningDetailes frontmatter={cleaningNode.i18n} className="pb-0 mb-0 bg-light" />
      <InspectionDetails frontmatter={inspectionNode.i18n} className="pt-0 mt-0 bg-light" />
      <AboutLandings frontmatter={aboutNode.i18n} className="bg-light" />

      <Contact
        frontmatter={contactNode.i18n}
        callbackNode={callbackNode.i18n}
        contactUsNode={contactUsNode.i18n}
        className="bg-dark"
      />
      <Footer
        frontmatter={footerNode.i18n}
        navigationNode={sitemapNode.i18n}
        contactUsNode={contactUsNode.i18n}
      />
    </div>
  );
};

Cleaning.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
};

Cleaning.defaultProps = {
  pageContext: {
    langKey: "en",
    defaultLang: "en",
    langTextMap: {},
  },
};

export default Cleaning;
